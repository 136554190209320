
import Vue from "vue";
import { InformationData } from "@/types/interfaces";
import Session from "@/components/Mentoree/Certificate/certificateTypes/Session.vue";
import Mentorcast from "@/components/Mentoree/Certificate/certificateTypes/Mentorcast.vue";
import Program from "@/components/Mentoree/Certificate/certificateTypes/Program.vue";

export default Vue.extend({
  name: "Certificate",
  components: {
    Session,
    Mentorcast,
    Program
  },
  props: {
    item: {
      type: Object as any
    },
    profile: Object
  },
  data() {
    return {
      currentTime: {},
      sessionDate: "",
      sessionItems: [
        {
          name: "start_date",
          label: (this as any).$t("certificate.certificateDate"),
          icon: "calendar",
          value: null
        },
        {
          name: "start_time",
          label: (this as any).$t("certificate.certificateTime"),
          icon: "time-square",
          value: null
        },
        {
          name: "price",
          label: (this as any).$t("certificate.certificatePaid"),
          icon: "price",
          value: null
        }
      ] as InformationData[]
    };
  },
  computed: {
    getBaseUrl(): any {
      return location.host;
    }
  }
});
